import React from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import './styles.scss';

const Careers = () => (
  <Container>
    <section className="Careers" id="careers">
      <div className="Careers-text">
        <div className="title">
          Careers
        </div>
        <p>
          We value human resources above all else. We believe investing in human potential is the only way to deliver outstanding results. Essential to this process is building an amazing place to work & grow.
        </p>
      </div>
    </section>
  </Container>
);

export default Careers;