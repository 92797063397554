import React from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import Image from '@images/intro.svg';
import './styles.scss';

const Introduction = () => (
  <Container>
    <section className="Introduction">
      <div className="Introduction-text">
        <div className="title">
          Help us make your <b>dream</b> a <b>reality</b>
        </div>
        <p>
          Dream Factory is a development &amp; consultancy agency building scalable and reliable solutions. We specialize in backend technologies, providing cutting edge solutions to our clients.
        </p>
        <Link to="/#contact" data-scroll>
          Let's talk
        </Link>
      </div>
      <div className="Introduction-image">
        <img
          src={Image}
          width="700px"
          alt="Dream Factory agency"
        />
      </div>
    </section>
  </Container>
);

export default Introduction;