import React from 'react';
import { Container } from '@components';
import AxentureLogo from '@images/axenture-logo.png';
import NextuserLogo from '@images/nextuser-logo.svg';
import OTPLogo from '@images/otp-logo.svg';
import KrakenLogo from '@images/kraken-logo.svg';
import './styles.scss';

const Clients = () => (
  <section className="Clients">
    <Container>
      <div className="Clients-list">
        <span>Trusted by:</span>
        <img src={AxentureLogo} alt="Axenture" height={40} />
        <img src={OTPLogo} alt="OTP" height={40} />
        <img src={NextuserLogo} alt="Nextuser" height={40} />
        <img src={KrakenLogo} alt="Kraken" height={40} />
      </div>
    </Container>
  </section>
);

export default Clients;