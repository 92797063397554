import React from 'react';
import { Container } from '@components';
import Image from '@images/about.svg';
import './styles.scss';

const About = () => (
  <section className="About" id="about">
    <Container>
      <div className="About-wrapper">
        <div className="About-image">
          <img
            alt="About us"
            src={Image}
            width="100%"
          />
        </div>
        <div className="About-text">
          <div className="title">
            <div className="section-title">
              <h2>
                About us
              </h2>
            </div>
            <h3>
              Challenge drive us
            </h3>
          </div>
          <p>
            We seek to find difficult problems to solve. Throughout the years we have built proven solutions in different industries: ranging from production, marketing, fintech, telecom to high-volume distributed retail.
          </p>
          <p>
            We strive to deliver without compromise while working at a fast pace, developing through an iterative process and focusing on delivering the highest value immediately. In our approach we combine both mature & stable platforms with cutting edge technologies such as blockchain, containerized microservices, kubernettes, etc. For us technology is a tool, we choose the tech stack which is best suited for the domain & problem at hand and the systems we build are self-sustaining and long running.
          </p>
        </div>
      </div>
    </Container>
  </section>
);

export default About;