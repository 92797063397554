import React from 'react';
import { Container, ContactCardTelephone, ContactCardMailForm } from '@components';
import './styles.scss';

const Contact = () => (
  <section className="Contact" id="contact">
    <Container>
      <div className="Contact-wrapper">
        <div className="Contact-text">
          <div className="title">
            Got project in mind?
          </div>
          <p>
            We're here to help and answer any question you might have. We look forward to hearing from you.
          </p>
        </div>
        <div className="Contact-cards">
          <ContactCardTelephone
            title="Give us a call"
          />
          <ContactCardMailForm
            title="Send us a message"
          />
        </div>
      </div>
    </Container>
  </section>
);

export default Contact;