import React from 'react';
import { Container, ServicesCard } from '@components';
import './styles.scss';

const Services = () => (
  <Container>
    <section className="Services" id="services">
      <div className="Services-text">
        <div className="title">
          <h2>
            Building <b>scalable</b> and <b>reliable</b> solutions
          </h2>
        </div>
        <p>
          Using the original principles of agile and extreme programming we are able to provide you with quick and tangible results.
        </p>
        <p>
          Fast feedback is what steers us in the right direction, reducing cost and time of development by orders of magnitude.
        </p>
      </div>
      <div className="Services-cards">
        <ServicesCard
          title="Highly Scalable Solutions"
          text="We’ve produced numerous scalable solutions ranging from high-traffic marketing applications, highly available microservices, enterprise database sync middleware to satellite relay systems."
        />
        <ServicesCard
          title="Full Stack Development"
          text="We offer complete services from specification to design, implementation, hosting and maintenance. If you need any of these services, or all of them don’t hesitate to contact us!"
        />
        <ServicesCard
          title="Enterprise Systems"
          text="Need a solution tailored by the specific needs of your organization? Our team has relevant experience developing large-scale systems in various business and technology domains."
        />
      </div>
    </section>
  </Container>
);

export default Services;