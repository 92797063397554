import React from 'react';
import SEO from "../components/seo"
import { Footer, Header, Page } from '@components';
import Introduction from '@sections/home/Introduction'
import Clients from '@sections/home/Clients'
import Services from '@sections/home/Services'
import About from '@sections/home/About'
import Careers from '@sections/home/Careers'
import Contact from '@sections/home/Contact'

const IndexPage = () => (
  <Page>
    <SEO
      title="Dream Factory - consulting & development"
    />
    <Header />
    <Introduction />
    <Clients />
    <Services />
    <About />
    <Careers />
    <Contact />
    <Footer />
  </Page>
)

export default IndexPage
